var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("ویدیو ها")]),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"align":"center","headers":_vm.headers,"items":_vm.videos.data,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.video_original_path",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"href":_vm.baseURL + item.video_original_path,"color":"#292C6D"}},[_vm._v("دانلود")])]}},{key:"item.video_hls_path",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"direction":"ltr!important","text-align":"left!important"}},[_vm._v(_vm._s(item.video_hls_path))])]}},{key:"item.edited_path",fn:function(ref){
var item = ref.item;
return [(item.edited_path)?_c('v-btn',{staticClass:"white--text",attrs:{"href":_vm.baseURL + item.edited_path,"color":"#292C6D"}},[_vm._v("دانلود")]):_vm._e()]}},{key:"item.watermark_path",fn:function(ref){
var item = ref.item;
return [(item.watermark_path)?_c('img',{staticClass:"mt-1",attrs:{"src":_vm.baseURL + item.watermark_path,"width":"100%"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","fab":"","color":"#EC255A"},on:{"click":function($event){_vm.selectedItem = item; _vm.dialog = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"color":"#161853","length":_vm.videos.last_page,"circle":""},on:{"input":_vm.getVideos},model:{value:(_vm.videos.current_page),callback:function ($$v) {_vm.$set(_vm.videos, "current_page", $$v)},expression:"videos.current_page"}})],1)],1),(_vm.selectedItem)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" آیا از حذف این ویدیو اطمینان دارید؟ ")]),_c('v-card-text'),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#292C6D","text":""},on:{"click":_vm.deleteVideo}},[_vm._v(" بله ")]),_c('v-btn',{attrs:{"color":"#EC255A","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" خیر ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }