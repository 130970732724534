<template>
  <div>
    <h3>ویدیو ها</h3>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
            align="center"
            :headers="headers"
            :items="videos.data"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer
        >
          <template v-slot:item.video_original_path="{item}">
            <v-btn :href="baseURL + item.video_original_path" color="#292C6D" class="white--text">دانلود</v-btn>
          </template>
          <template v-slot:item.video_hls_path="{item}">
            <p style="direction: ltr!important; text-align: left!important;">{{ item.video_hls_path }}</p>
          </template>
          <template v-slot:item.edited_path="{item}">
            <v-btn v-if="item.edited_path" :href="baseURL + item.edited_path" color="#292C6D" class="white--text">دانلود</v-btn>
          </template>
          <template v-slot:item.watermark_path="{item}">
            <img v-if="item.watermark_path" class="mt-1" :src="baseURL + item.watermark_path" width="100%">
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn x-small fab color="#EC255A" @click="selectedItem = item; dialog = true"><v-icon color="white">mdi-close</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="videos.current_page"
            :length="videos.last_page"
            circle
            @input="getVideos"
        ></v-pagination>
      </v-col>
    </v-row>

    <!--   Dialog   -->
    <v-dialog
        v-if="selectedItem"
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title>
          آیا از حذف این ویدیو اطمینان دارید؟
        </v-card-title>

        <v-card-text>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#292C6D"
              text
              @click="deleteVideo"
          >
            بله
          </v-btn>
          <v-btn
              color="#EC255A"
              text
              @click="dialog = false"
          >
            خیر
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Videos',

  data() {
    return {
      videos: [],

      headers: [
        {text: 'شناسه', value: 'id', align: 'center'},
        {text: 'شناسه ی یکتای کاربر', value: 'client_video_uid', align: 'center'},
        {text: 'فایل اصلی', value: 'video_original_path', align: 'center'},
        {text: 'لینک hls', value: 'video_hls_path', align: 'center'},
        {text: 'callback', value: 'callback', align: 'center'},
        {text: 'مدت زمان (دقیقه)', value: 'duration', align: 'center'},
        {text: 'نوع ویرایش', value: 'edit_type', align: 'center'},
        {text: 'فایل ویرایش شده', value: 'edited_path', align: 'center'},
        {text: 'واترمارک', value: 'watermark_path', align: 'center'},
        {text: 'عملیات', value: 'actions', align: 'center'},
      ],

      selectedItem: null,
      dialog: false,
    }
  },

  methods: {
    getVideos() {
      let page = this.videos.current_page ?? 1
      window.axios
          .get('/user/videos/index?page=' + page)
          .then((res) => {
            this.videos = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    deleteVideo() {
      window.axios
          .post('/user/videos/delete/' + this.selectedItem.id)
          .then(() => {
            this.openSnackbar('ویدیو مورد نظر حذف شد', 'success')
            this.getVideos()
          })
          .catch((err) => {
            console.log(err)
          })
      this.dialog = false
      this.selectedItem = null
    }
  },

  mounted() {
    this.getVideos()
  }
}
</script>

<style scoped>

</style>